@import '../../style/variable/variable.scss';

.what_are_we{
    width: 100%;
    background-image: url(../../images/say_hello_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #FCFAFA;
    display: inline-block;
    background-position-x: left;
    background-position-y: center;

    .right_text {
        background-color: #fff;
        padding: 26px 50px;
        border-radius: 5px;
        box-shadow: -8px 5px 10px 0px rgba(0, 0, 0, 0.05);
        z-index: 1;
        margin-left: 80px;

        p {
            width: auto;
        }
    }

    .main_title:after {
        display: none;
    }
}

// .aboutus {

//     .what_are_we{
    
//         .main_title:before {
//             background-repeat: repeat-y;
//             width: 300px;
//         }
//     }
// }

@media only screen and (max-width: 992px){
    .what_are_we{

        .flex_wrap {
            flex-direction: column-reverse;
            height: auto;
            padding-top: 80px;
            align-items: flex-start;
        }

        .left_img {
            flex: auto;
        }

        .right_text {
            flex: auto;
            margin-left: 0px;
            margin-bottom: 40px;
        }

    }
}

@media only screen and (max-width: 768px){
    .what_are_we .flex_wrap {
        align-items: center;
        padding-top: 50px;
    }
}

@media only screen and (max-width: 576px){
    .what_are_we .right_text {
        padding: 15px 26px;
    }
}