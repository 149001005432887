@import '../../style/variable/variable.scss';

footer {
    background-image: url(../../images/footer_bg.png);
    background-size: cover;
    background-repeat: no-repeat;

    .flex_wrap {
        flex-direction: column;
        justify-content: flex-end;
    }

    .footer_top {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 30px;
    }

    .left_text {

        a {
            color: #000000;
            cursor: initial;
        }

        a:hover {
            color: #2e3280;
        }

        a:after {
            content: "";
            width: 21px;
            height: 14px;
            display: inline-block;
            background-image: url(../../images/contact_right_arrow.svg);
            background-repeat: no-repeat;
            vertical-align: middle;
            margin-left: 5px;
        }
        
    }

    // .right_img {
        
    //     img {
    //         margin: 10px;
    //         box-shadow: -10px 10px 16px 0px rgba(0, 0, 0, 0.16);
    //     }
    // }

    .footer_bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 30px;

        .contact_details {
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;

            ul {
                list-style: none;
                margin: 0px;
                padding: 0 100px 0 0;

                li {
                    color: #ffffff;
                    font-size: 14px;
                    line-height: 25px;

                    a {
                        color: #ffffff;
                    }

                    a:hover {
                        color: #000000;
                    }
                }

                strong {
                    font-size: 16px;
                    margin-bottom: 8px;
                    display: block;
                }
            }
            
        }
    }

    .footer_last {
        width: 100%;
        border-top: 1px solid #C9A5FF;

        .last_content {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;

            p {
                margin: 0px;
                color: #fff;
                font-size: 12px;
            }

            .nav {

                margin: 15px;

                a {
                    padding: 14px 15px;
                    color: #ffffff;
                    font-size: 12px;
                }

                a:hover {
                    color: #000000;
                }

            }

        }
    }

}

@media only screen and (max-width: 1200px){
    

    footer {
        
        .main_title {
    
            br {
              display: none;
            }
        }

        .footer_top {
            flex: 100%;
            align-items: flex-end;
        }

        .right_img {
            display: flex;
            // flex: 70%;
            justify-content: flex-end;
            align-items: center;
    
            img {
                width: 100%;
                max-width: none;
            }
        }
    
        // .left_text {
        //     flex: 30%;
        // }
        .footer_bottom {

            .contact_details {
                
                ul {
                    list-style: none;
                    margin: 0px;
                    padding: 0px 0px 0px 40px;
                }
            }
        }
    }

    
}

@media only screen and (max-width: 992px){
    .footer_logo {

        width: 130px;

        img {
            max-width: none;
            width: 100%;
        }
    }
}

@media only screen and (max-width: 768px){

    footer {

        .footer_bottom {
            flex-direction: column;
            justify-content: center;
            flex: none;

            .contact_details {
                justify-content: space-evenly;
                width: 100%;
                text-align: left;

                ul {
                    padding: 0px
                }
            }
        }

        .left_text {
            flex: none;
        }

        .footer_top {
            align-items: center;
            flex-direction: column-reverse;
        }
        
        .right_img {

                justify-content: center;
                
                img {
                    width: 80%;
            }
            
        }

        .footer_last {

            .last_content {

                p {
                    flex: 50;
                }

                .nav {
                    margin: 15px 0 15px 0;
                    flex: 50;
                    justify-content: flex-end;
                }

            }

        }
}

}

@media only screen and (max-width: 576px){
    footer {

        .footer_bottom {

            .contact_details {

                justify-content: space-between;

            }

        }

        .footer_last {

            .container {
                padding: 10px 20px;
            }

            .last_content {
                flex-direction: column-reverse;

                p {
                    text-align: center;
                }

                .nav {
                    margin: 0px 15px 5px 15px;

                    a {
                        padding: 0 15px;
                    }
                }
            }

        }

    }
}

@media only screen and (max-width: 480px){
    footer {

        .footer_bottom {

            .contact_details {
                justify-content: space-between;
                flex-direction: column;
                align-items: center;
                text-align: center;

                ul {
                    padding: 0px 0px 10px 0px;
                    margin-bottom: 10px;
                    border-bottom: 1px solid #e0ddef;
                    width: 80%;
                }

                ul:nth-last-child(1) {
                    border-bottom: none;
                    padding: 0;
                    margin: 0;
                }
            }

        }

    }
}

@media only screen and (max-width: 400px){
    footer {
    
        .right_img {
                
                img {
                    width: 330px;
                    margin-top: 70px;
            }
            
        }
    }
}