@import '../../style/variable/variable.scss';

.get_in_touch_wrapper{
    width: 100%;
    background-image: url(../../images/say_hello_bg.png);
    background-size: auto;
    background-repeat: no-repeat;
    display: inline-block;

    .flex_wrap {
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;

        .questions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            padding-bottom: 50px;
            flex: none;

            p {
                margin: 0px 10px  0px 0px;
            }

            svg {
                width: 83px;
                height: 83px;
            }
        }

        .career_form {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            .right_img {
                flex: 50%;
            }
        }
    }

    .flex_wrap>p {
        color: #767676;
        width: 60%;
        line-height: 26px;
    }
}

.career_form {

    .form_btn_wrapper {
        position: relative;
        
        .file_div {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            border: 1px solid #E5E5E5;
            border-radius: 5px;
            padding: 15px 5px;

            input {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 15px 5px;
                margin: 0;
                width: 105px;
                opacity: 0;
                position: absolute;
                top: 0;
                left: 0;
            }

            svg {
                width: 20px;
                margin-right: 5px;                
            }

            label {
                font-size: 13px;
            }

    }

    }

    .btn {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 14px 15px;
        margin: 0;
        width: auto;

        
    }

    .success_msg {
        color: #0012ff;
        font-size: 14px;
        margin: 10px 0 0 0;
        width: 100%;
        text-align: center;
        padding: 15px 10px;
        background-color: #e1e5ff;
        border-radius: 5px;
    }
    .error_msg_upload {
        color: #E17643;
        font-size: 14px;
        margin: 10px 0 0 0;
        width: 100%;
        text-align: center;
        padding: 15px 10px;
        background-color: #ffe3d5;
        border-radius: 5px;
    }
}

@media only screen and (max-width: 992px) {
    .career_form .form_btn_wrapper .file_div {
        padding: 10px 3px;
    }

    .career_form .form_btn_wrapper .file_div input {
        padding: 8px 3px;
        width: 100px;
    }
}

@media only screen and (max-width: 768px){
    .carrier {

        .get_in_touch_wrapper {

                .flex_wrap {
                    align-items: center;
                    justify-content: center;

                    .career_form {
                        flex-direction: column;

                        .right_img {
                            flex: auto;
                            margin-top: 40px;
                        }
                    }

                    .questions {
                        padding-bottom: 0px;
                        flex-direction: column;
                        justify-content: center;
                    }
                }

                .flex_wrap>p {
                    width: 100%;
                }
            
                .main_title:after {
                    display: none;
                }
        
                .main_title:before {
                    background-repeat-x: repeat;
                }
        }

    }

}