@import '../../style/variable/variable.scss';

.careers_services_wrapper{
    padding: 100px 0;

    .container {
        max-width: 850px;
    }

    .sc-AxhUy.jQoddj {
        height: 150px;
    }

    .dXrOHt {
        min-height: auto;
    }

    .ewqrkn {
        background-color: #efefef;
        box-shadow: 0 0 1px 3px rgb(202, 202, 202);
    }

    .cIWxKc {
        background-color: white;
        box-shadow: 0 0 1px 2px rgba(162, 162, 162, 0.5);
    }

}

.flex_wrap_careers {
    width: 100%;
    height: 156px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px;
    background-color: #FFEDE0;
    box-shadow: -8px 5px 10px 0px rgba(0, 0, 0, 0.06);
    position: relative;
    margin: 0;

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        img {
            width: 100px;
        }

        p {
            margin: 0px;
            color: $primary-color;
            line-height: 26px;
        }
    }

    .carousel_img {
        border-right: 1px solid #2E3280;
        margin-right: 30px;
        padding-right: 30px;

        // img {
        //     max-width: 100%;
        // }
    }
}

.flex_wrap_careers:after  {
    content: "";
    width: 14px;
    height: 156px;
    display: block;
    background-image: url(../../images/carrier_service_border.svg);
    background-repeat: no-repeat;
    position: absolute;
    left: 0px;
}

@media only screen and (max-width: 1200px) {
    .carousel_img {
        img {
            max-width: none; 
        }
    }
}

@media only screen and (max-width: 768px) {

    .careers_services_wrapper {
        padding: 40px 0;
    }

    .flex_wrap_careers {

        height: auto;
        
            li {
                flex-direction: column;
                text-align: center;

                .carousel_img {
                    border: none;
                    margin-right: 0px;
                    padding-right: 0px;
                }
        }
    }

    .flex_wrap_careers:after {
        display: none;
    }

    .rec.rec-carousel {
        height: 270px!important;
    }
}

@media only screen and (max-width: 480px){
    .rec.rec-carousel {
        height: 300px !important;
    }
}

@media only screen and (max-width: 400px){
    .rec.rec-carousel {
        height: 340px !important;
    }
}