@import '../../style/variable/variable.scss';

.payment_wrapper {
    background-image: url(../../images/payment_gateway_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
}

.left_text {
    p {
        width: 70%;
        color: #767676;
        line-height: 26px;
    }
}

@media only screen and (max-width: 992px) {
    .left_text {
        p {
            width: auto;
        }
    }
}