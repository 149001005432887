@import './style/variable/variable.scss';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body{
  margin: 0;
  padding: 0;
  font-family: open_sansregular;
}

*{
  box-sizing: border-box;
}

a{
  text-decoration: none;
  cursor: pointer;
  -webkit-transition: color 0.1s linear 0s;	
    -moz-transition: color 0.1s linear 0s;
    -o-transition: color 0.1s linear 0s;
        transition: color 0.1s linear 0s;
}

a:hover,
a:active,
a:focus{
  outline: 0;
  color:#000;
  text-decoration:none;
}

a img {
  border: none;
}

p {
  // margin: 0 0 30px;
  margin: {
    top: 0;
    right: 0;
    bottom: 30px;
    left: 0;
  }
  font-size: 16px;
}

input:focus, 
textarea:focus,
input[type="text"]:focus {
  outline: 0;
}

input, button, select, textarea {
  outline: 0;
}

.main_wrapper {
  width: 100%;
  position: relative;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: block;
}

.primary_btn {
  display: inline-block;
  padding: 14px 54px;
  border-radius: 5px;
  font-size: 14px;
  text-transform: capitalize;
  border: none;
  color: #fff!important;
  background: rgb(240,168,107);
  background: linear-gradient(90deg, rgba(240,168,107,1) 0%, rgba(225,118,67,1) 100%);
}

.primary_btn:hover {
  color: #000!important;
  background: rgb(247,229,214);
  background: linear-gradient(90deg, rgba(247,229,214,1) 0%, rgba(225,118,67,1) 100%);
}

.secondary_btn {
  display: inline-block;
  padding: 14px 54px;
  border-radius: 5px;
  font-size: 14px;
  text-transform: capitalize;
  border: none;
  color: #fff!important;
  background: rgb(174,147,242);
  background: linear-gradient(90deg, rgba(174,147,242,1) 0%, rgba(127,107,212,1) 100%);
}

.secondary_btn:hover {
  color: #2f00ca !important;
  background: #ae93f2;
  background: linear-gradient(90deg, #d0c2f5 0%, #542fef 100%);
}

.btn {
  display: inline-block;
  padding: 14px 26px;
  border: 1px solid #E5E5E5;
  border-radius: 5px;
  font-size: 14px;
  text-transform: capitalize;
  color: #767676;
  background: transparent;
  cursor: pointer;
}

.form {
  flex: 50%;
  color: #767676;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-shadow: -8px 5px 10px 0px rgba(0, 0, 0, 0.05);
  padding: 50px;
  background-color: #fff;
}

.form_btn_wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;

  p {
        margin: 0;
        font-size: 13px;
    }
}

.error_msg {
  color: #E17643;
  font-size: 12px;
  margin: 0;
  display: none;
}

.main_title {
  font-size: 45px;
  color: $primary-color;
  font-family: $font-stack-bold;
  line-height: 55px;
  margin: 0 0 15px 0;
}

.right_text p {
  width: 70%;
  color: #767676;
  line-height: 26px;
}

header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 30px 20px;
  position: absolute;
  top: 0;
  width: 1200px;
}

.nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;

  a {
      font-size: 14px;
      color: #464646;
      padding: 14px 54px;
      border-radius: 5px;
      text-transform: uppercase;
  }

  a:hover {
      color: #E17643;
  }
}

input, select, textarea {
  width: 100%;
  border: 1px solid #E5E5E5;
  padding: 12px;
  margin-bottom: 12px;
  color: #767676;
  font-size: 14px;
  border-radius: 5px;
  font-family: open_sansregular;
}

.container::after {
  clear: both;
  content: " ";
  display: block;
}

@media only screen and (max-width: 1200px){
  .container {
    max-width: none;
    width: 100%;
    padding: 0 20px;
    }

    img {
      max-width: 100%;
    }

    .flex_wrap>div {
      flex: 50%;
  }
}

@media only screen and (max-width: 992px){

  .main_title {
    font-size: 35px;
    line-height: 44px;
}

  .primary_btn {
    padding: 10px 30px;
  }

  .secondary_btn {
    padding: 10px 30px;
  }

  .left_text p {
    width: auto;
}

.right_text p {
  width: auto;
}
      
}

@media only screen and (max-width: 768px){

  .main_title {
    
      br {
        display: none;
      }
  }

  .flex_wrap>div {
    flex: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  p {
    font-size: 14px;
  }

  .left_img, .right_img {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      max-width: 80%;
    }

  }

  .primary_btn {
    padding: 5px 15px;
  }

  .secondary_btn {
    padding: 5px 15px;
  }

  .heigher_wrapper, 
  .payment_wrapper, 
  .banking_wrapper, 
  .artificial-intelligence_wrapper {
    padding: 80px 0;
}

.form {
  width: 100%;
  padding: 30px;
  flex: auto;
}

.flex_wrap {

  .banner_text {
    margin-top: 60px;
  }

}

}

@media only screen and (max-width: 576px){
  .main_title {
    font-size: 28px;
    line-height: 35px;
  }
}

@media only screen and (max-width: 480px){
  .heigher_wrapper, 
  .payment_wrapper, 
  .banking_wrapper, 
  .artificial-intelligence_wrapper {
    padding: 50px 0;
  }
}

@media only screen and (max-width: 400px){
  .left_img img, .right_img img {
      max-width: 260px;
  }
}

