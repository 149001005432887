@import '../../style/variable/variable.scss';

.mobile_wrapper {

    .right_text {
        padding-left: 100px;

        .specs {
            width: 455px;
            list-style: none;
            padding: 0;
            margin-top: 50px;
            text-align: left;
            height: 350px;
            position: relative;
            display: none!important;

            .circle_one {
                width: 216px;
                height: 216px;
                left: 0;
                bottom: 0;
                padding-left: 50px;
            }

            .circle_two {
                width: 194px;
                height: 194px;
                top: 0px;
                left: 70px;
                padding-left: 60px;
                z-index: 1;
            }

            .circle_three {
                width: 272px;
                height: 272px;
                top: 110px;
                right: 10px;
                padding-left: 80px;
            }

            li {
                border-radius: 12em;
                background-color: #ffffff;
                box-shadow: -8px 5px 10px 0px rgba(0, 0, 0, 0.05);
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: center;
                position: absolute;
                color: #767676;
                font-size: 14px;

                span {
                    margin-top: 10px;
                }
            }
        }
    }
}

@media only screen and (max-width: 992px) {
    .mobile_wrapper {

        padding: 60px 0;

        .right_text {
            padding-left: 0px;

            .specs {
                width: 100%;
                height: auto;
                position: static;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;

                li {

                    img {
                        width: 45px;
                    }
                }

                .circle_one, .circle_two, .circle_three {
                    width: 216px;
                    height: 216px;
                    padding: 0;
                    position: static;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                }
            }
        }
    }
}

@media only screen and (max-width: 768px){

    .mobile_wrapper {

        .right_text {
            
            .specs {
                flex-direction: column;
                margin-top: 30px;
                display: none;

                .circle_one, .circle_two, .circle_three {
                    margin: 10px;
                }
            }

        }

    }

}