@import '../../style/variable/variable.scss';

.aboutus {

    .banner_text {
        z-index: 3;

        .main_title {
            width: 330px;
        }
    }

    .main_title:after  {
        content: "";
        height: 12px;
        display: block;
        background-image: url(../../images/carrier_text_bg2.svg);
        background-repeat: no-repeat;
        z-index: -1;
        position: relative;
        bottom: 18px;
        background-size: contain;
    }

    .main_title:before  {
        content: "";
        height: 12px;
        display: block;
        background-image: url(../../images/carrier_text_bg1.svg);
        background-repeat: no-repeat;
        position: relative;
        top: 50px;
        z-index: -1;
        background-size: contain;
    }

}

@media only screen and (max-width: 1200px){
    
    .aboutus {

        .banner_text {
            flex: auto;

            // .main_title {
            //     width: auto;
            // }
        }

        // .banner_img {
        //     flex: auto;
        // }
    }
}

@media only screen and (max-width: 992px){
    
    .aboutus {

        .banner_text {
            flex: auto;

            .main_title {
                width: 230px;
            }
        }

        .main_title:before {
            top: 40px;
    }

    }
}

@media only screen and (max-width: 576px){
    
    .aboutus {

        .banner_text {

            .main_title {
                width: 190px;
            }
        }

        .main_title:before {
            top: 33px;
        }

    }
}

@media only screen and (max-width: 480px){
    .aboutus {
        
        .banner_img {
            
            img {
                max-width: 260px;
            }

        }
    }
  }