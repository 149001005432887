.looking_to_grow_wrapper {
    background-image: url(../../images/looking_to_grow_bg.png);
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: right;

    .right_text {
        padding-left: 40px;
    }

}

.jobs_btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;

    svg {
        width: 15px;
        height: 15px;
        margin-right: 5px;
    }

    a:after {
        content: "";
        width: 14px;
        height: 8px;
        display: inline-block;
        background-image: url(../../images/jobs_arrow_icon.svg);
        background-repeat: no-repeat;
        vertical-align: middle;
        margin-left: 5px;
    }

}

@media only screen and (max-width: 768px){

    .looking_to_grow_wrapper {
        padding: 50px 0;
        position: relative;
        z-index: 0;

        .flex_wrap {
            flex-direction: column-reverse;
        }

        .right_text {
            padding-left: 0px;
            padding-bottom: 30px;
        }

    }

    .carrier {

        .looking_to_grow_wrapper {
        
            .main_title:after {
                display: none;
            }

            .main_title:before {
                background-repeat: repeat;
            }
        }
    }

}