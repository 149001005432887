@import '../../style/variable/variable.scss';

.carrier_banner {
        width: 100%;
        // height: 100vh;
        background-image: url(../../images/careers-bg.png);
        background-size: cover;
        background-repeat: no-repeat;
        top: 0;
        z-index: -1;
}

.banner_img {

        img {
                max-width: 100%;
        }
}

@media only screen and (max-width: 992px){
        .carrier_banner {

                .flex_wrap {
                        height: 100%;
                        padding: 80px 20px 50px 20px;
                }

        }

}

@media only screen and (max-width: 768px){
        .carrier_banner {

                .flex_wrap {
                        padding: 0px 20px 50px 20px;
                }

        }

}