@font-face {
    font-family: 'open_sansbold';
    src: url('./opensans-bold-webfont.woff2') format('woff2'),
         url('./opensans-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
  }

@font-face {
    font-family: 'open_sansregular';
    src: url('./opensans-regular-webfont.woff2') format('woff2'),
         url('./opensans-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}