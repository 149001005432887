@import '../../style/variable/variable.scss';

.banner_wrapper {
    width: 100%;
    // height: 910px;
    height: auto;
    background-image: url(../../images/banner_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    z-index: -1;

    .banner_text {
        flex: 50%;
        margin-top: 100px;
    }

    .banner_info {
        color: #767676;
        line-height: 26px;
        padding-right: 5em;
    }

    .promotes {
        width: 210px;
        height: 210px;
        border-radius: 8em;
        background-color: #FFF7FF;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        position: relative;
        top: 140px;
        right: -50px;
        -webkit-box-shadow: -8px 5px 10px 0px rgba(0,0,0,0.15);
        -moz-box-shadow: -8px 5px 10px 0px rgba(0,0,0,0.15);
        box-shadow: -8px 5px 10px 0px rgba(0,0,0,0.05);

        h4 {
            color: #2E3280;
            font-size: 25px;
            font-family: $font-stack-regular;
            font-weight: normal;
            margin: 0 0 15px 0;
            position: absolute;
            top: 30px;
            right: -80px;
        }

        .secondary_btn {
            position: absolute;
            top: 145px;
            right: -80px;
        }
    }
}

.flex_wrap {
    height: 100vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;

    .banner_img img {
        margin-top: 2em;
    }

    .banner_img {
        flex: 50%;
    }
}

@media only screen and (max-width: 992px){

    .banner_wrapper {
        background-position-y: -3px;

        .banner_text {
            margin-top: 20px;
        }

        .promotes {
            width: 190px;
            height: 190px;
            margin: 0 0 0 30px;
            display: block;
            top: 30px;
            right: unset;

            h4 {
                font-size: 20px;
                top: 40px;
                right: -30px;
            }

            .secondary_btn {
                top: 135px;
                right: -22px;
            }
        }
    }
        
  }

@media only screen and (max-width: 768px){

    .banner_wrapper {
    
        .banner_text {
            margin-top: 60px;
        }

        .banner_info {
            padding-right: 0;
        }

        .promotes {
            margin: 0;
            top: -40px;
            left: -190px;

            h4 {
                font-size: 16px;
                top: 35px;
                right: 30px;
                text-align: left;
            }

            .secondary_btn {
                top: 113px;
                right: 37px;
            }
        }

        .flex_wrap {
            flex-direction: column;
            justify-content: flex-start;
            height: auto;
    
            .banner_img { 
    
                display: flex;
                justify-content: flex-end;
                
                img {
                    max-width: 70%;
                }
            }
    
        }
    }

    .flex_wrap {
        flex-direction: column;
        justify-content: flex-start;
        text-align: center;
        padding: 0;
        height: auto;
    }    
  }

  @media only screen and (max-width: 576px){
    .banner_wrapper {

        .promotes {
            width: 150px;
            height: 150px;
            top: 330px;
            left: 0;

            h4 {
                right: -7px;
                font-size: 14px;
            }

            .secondary_btn {
                top: 99px;
                right: -13px;
            }
        }

    }
  }

  @media only screen and (max-width: 480px){
    .banner_wrapper {

        .flex_wrap {

            .banner_img img {
                max-width: 260px;
            }

        }

        .promotes {
            top: 280px;
            left: 0;
        }

    }
  }