

.aboutus {

    .find_map {
            
        .main_title:after {
            width: 99px;
            background-repeat-y: repeat;
        }

        .main_title:before {
            width: 220px;
        }
    }

}

.find_map {

    .right_img{
        flex: 65%;
        box-shadow: -8px 5px 10px 0px rgba(0, 0, 0, 0.09);

        img {
            max-width: 100%;
        }

        .iframe_block {
            display: flex;
            width: 100%;
        }

        .mapouter {
            width: 100%;
        }
    }

    .left_text {
        flex: 35%;
        padding-right: 30px;
        // box-shadow: 5px 5px 10px -3px rgba(0, 0, 0, 0.09);
        // border-radius: 200px;
        background-image: url(../../images/find_us_text_bg.png);
        background-size: contain;
        background-repeat: no-repeat;
        z-index: 1;
    }

}

.map_locate {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 20px 0 20px 20px;
    position: relative;

    p {
        width: 100%;
        margin: 0;
        color: #000000;
    }
}

.map_locate:after {
    content: "";
    width: 64px;
    height: 64px;
    background-color: #F8C186;
    display: inline-block;
    position: absolute;
    left: 0px;
    top: 14px;
    z-index: -1;
    border-radius: 5px;
}

@media only screen and (max-width: 992px){

    .find_map {

        .flex_wrap {
            flex-direction: column;
            height: auto;
            padding: 80px 0;
            align-items: flex-start;
            justify-content: center;
        }

        .left_text {
            flex: auto;
            padding-right: 0px;
            padding-bottom: 50px;
            margin-bottom: 30px;

            p {
                margin-bottom: 0;
            }

            .main_title {
                margin: 0;
            }
        }

        .right_img{
            flex: auto;
            width: 100%;
        }

    }

    .aboutus {
        
        .find_map {
            
            .main_title:before {
                width: 170px;
                }
            }
        }

}

@media only screen and (max-width: 768px){
    .find_map {

        .left_text {
            width: 100%;
            background-position-x: center;
        }

        .flex_wrap {
            padding: 50px 0;
        }

        .map_locate {
            padding: 20px;
        }

        .map_locate:after {
            left: 84px;
        }

    }

    .aboutus {
        
        .find_map {
            
            .main_title:after {
                display: none;
                }
            }
        }

    .aboutus {
    
        .find_map {
            
            .main_title:before {
                width: 260px;
                }
            }
        }
}