@import '../../style/variable/variable.scss';

.artificial-intelligence_wrapper {
    background-image: url(../../images/artificial_intelligence_bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-color: #F8F7FA;
    
    .left_text {
        width: 50%;
    }

    .right_img {
        width: 50%;

        img {
            max-width: 100%;
        }
    }
}

@media only screen and (max-width: 1200px){

    .artificial-intelligence_wrapper {
    
        .flex_wrap {

            .left_text {
                width: auto;
                margin-top: 0;
            }

            .right_img {
                width: auto;
                margin-top: 0;
            }
        }
    }
}

@media only screen and (max-width: 992px){
    .artificial-intelligence_wrapper {
    
        .flex_wrap {

            .left_text {
                flex: 40%;
            }

            .right_img {
                flex: 60%;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    .artificial-intelligence_wrapper {
    
        .right_img {

            img {
                max-width: 80%;
            }

        }
    }
}

@media only screen and (max-width: 400px){

    .artificial-intelligence_wrapper {
        
            .right_img img {
                max-width: 260px;
            }
        }
  }