@import '../../style/variable/variable.scss';

.heigher_wrapper {
    background-color: #FCFAFA;

    .right_text {
        text-align: right;
        position: relative;

        p {
            width: auto;
            color: #000000;
            margin: 0;
        }

        .rgt_algn {
            font-size: 15px;
            color: #767676;
            line-height: 26px;
            margin: 80px 0px 0px 0px;
            z-index: 1;
            position: relative;
            padding-right: 25px;
        }

        .rgt_algn:after {
            content: "";
            width: 203px;
            height: 190px;
            background-color: #EECEFF;
            border-radius: 5px;
            display: inline-block;
            position: absolute;
            bottom: -32px;
            right: 0;
            z-index: -1;
            -webkit-box-shadow: 10px 12px 6px 0px rgba(0,0,0,0.06);
            -moz-box-shadow: 10px 12px 6px 0px rgba(0,0,0,0.06);
            box-shadow: 10px 12px 6px 0px rgba(0,0,0,0.06);
        }
    }
}

@media only screen and (max-width: 992px){
    .heigher_wrapper {
        
        .right_text {

            .rgt_algn {
                font-size: 14px;
                margin: 70px 0px 0px 0px;

                br {
                    display: none;
                }
            }
            
            .rgt_algn:after {
                width: 100%;
                height: 100%;
                top: -9px;
                bottom: auto;
                padding: 10px;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    .heigher_wrapper {

        .flex_wrap {
            flex-direction: column-reverse;
        }
        
        .right_text {

            text-align: center;
        
            .rgt_algn {
                font-size: 14px;
                margin: 30px 0px 0px 0px;
                padding: 20px;
                background-color: #EECEFF;
                border-radius: 5px;
                -webkit-box-shadow: 10px 12px 6px 0px rgba(0,0,0,0.06);
                -moz-box-shadow: 10px 12px 6px 0px rgba(0,0,0,0.06);
                box-shadow: 10px 12px 6px 0px rgba(0,0,0,0.06);
            }

            .rgt_algn:after {
                display: none;
            }
        }

        .left_img {
            padding-top: 40px;
        }
    }
}

@media only screen and (max-width: 480px){
    
}