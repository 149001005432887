.about_banner {
    width: 100%;
    // height: 100vh;
    background-image: url(../../images/careers-bg.png);
    background-size: cover;
    background-repeat: no-repeat;
    top: 0;
    z-index: -1;
}

.aboutus {

    .banner_text{

        .main_title:before {
            width: 226px;
        }
    }
}


@media only screen and (max-width: 992px){
    .aboutus {

        .banner_text{
    
            .main_title:before {
                width: 177px;
            }

            .main_title:after {
                width: 177px;
            }
        }
    }
}

@media only screen and (max-width: 768px){
    .aboutus {

        .banner_text{
    
            .main_title:before {
                left: 25px;
            }

            .main_title:after {
                left: 25px;
            }
        }
    }
}

@media only screen and (max-width: 576px){
    .aboutus {

        .banner_text{
    
            .main_title:before {
                width: 140px;
                background-repeat: no-repeat;
            }

            .main_title:after {
                width: 140px;
                background-repeat: no-repeat;
                bottom: 14px;
            }
        }
    }
}