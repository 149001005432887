/* ==================================================
   Media Queries
================================================== */

@media only screen and (max-width: 1200px){
    .container.header {
        padding: 0;
    }

    header {
        width: 100%;
    }
}

@media only screen and (max-width: 992px){
    header {
        padding: 20px;

        .logo {
            width: 150px;
        }

        img {
            max-width: none;
            width: 100%;
        }

        .nav a {
            padding: 10px 30px;
        }
    }
}

@media only screen and (max-width: 768px){
    header {

        .logo {
            width: 120px;
        }

        flex-direction: column;
        position: static;
        background-color: #fff7ff;
    
        .nav a {
            padding: 5px 15px;
        }

        .flex_wrap {
            flex-direction: column;
            justify-content: flex-start;
        }
    }
}

@media only screen and (max-width: 600px){
    

    
        
}

@media only screen and (max-width: 469px){

}